import * as React from 'react';
import { Text, Link, MainContainer } from '~/components';
import styled from '@emotion/styled';
import { TEXT_KEYS } from '~/service/locale';
import TextButton from '../components/buttons/TextButton';

const Container = styled(MainContainer)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 1,
});

const GoBack = styled(TextButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

function FourOFour() {
  return (
    <Container>
      <Text variant="title" text={TEXT_KEYS.SOMETHING_WENT_WRONG}></Text>
      <GoBack to="/" text={TEXT_KEYS.GO_BACK} />
    </Container>
  );
}

export default FourOFour;
